var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userLoginCodesData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t("UserLoginCodes.data")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("UserLoginCodes.loginCode")))]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t("UserLoginCodes.codeIsActive")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("PlatFroms.modelName")))]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.$t("UserLoginCodes.loginCodeStatus")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.image")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("general.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("UserLoginCodes.codeIsActiveFrom")))]),_c('th',[_vm._v(_vm._s(_vm.$t("UserLoginCodes.codeIsActiveTo")))]),_c('th',[_vm._v(_vm._s(_vm.$t("UserLoginCodes.codeIsActiveDuration")))]),_c('th',[_vm._v(_vm._s(_vm.$t("UserLoginCodes.loginCodeStatusUsed")))]),_c('th',[_vm._v(_vm._s(_vm.$t("UserLoginCodes.loginCodeStatusExpired")))])])]),_c('tbody',_vm._l((_vm.userLoginCodesData),function(userLoginCode,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.filterData.currentIndex))]),_c('td',[_c('img',{staticClass:"item-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                userLoginCode.userLoginCodeImagePath,
                _vm.defaultImg
              ),"onerror":("this.src='" + _vm.defaultImg + "'")}})]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(userLoginCode.fullCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(userLoginCode.userLoginCodeNameCurrent))+" ")]),_c('td',[_c('span',{staticClass:"font-en"},[_vm._v(_vm._s(_vm.isDataExist(userLoginCode.loginCode)))])]),_c('td',[_vm._v(" "+_vm._s(_vm.formateDateTimeLang( userLoginCode.codeActiveFromDate, userLoginCode.codeActiveFromTime ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formateDateTimeLang( userLoginCode.codeActiveToDate, userLoginCode.codeActiveToTime ))+" ")]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(userLoginCode.codeActiveDurationCurrent)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(userLoginCode.userLoginCodePlatFromNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(userLoginCode.loginCodeIsUsedStatus ? _vm.$t("yes") : _vm.$t("no"))+" ")]),_c('td',[_vm._v(" "+_vm._s(userLoginCode.loginCodeIsExpiredStatus ? _vm.$t("yes") : _vm.$t("no"))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setUserLoginCodeData(userLoginCode);
                  _vm.openBottomSheet('UserLoginCodeInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('UserLoginCodes.qrCode')},on:{"click":function($event){_vm.setUserLoginCodeData(userLoginCode);
                  _vm.openBottomSheet('UserLoginCodeQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.hasPrivilegeEdit || _vm.ableToEdit(userLoginCode))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setUserLoginCodeData(userLoginCode);
                  _vm.openBottomSheet('UserLoginCodeUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.hasPrivilegeFinaleDelete)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.UserLoginCodeDelete",modifiers:{"UserLoginCodeDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setUserLoginCodeData(userLoginCode)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.hasPrivilegeChangeActivationType)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.UserLoginCodeChangeActivationType",modifiers:{"UserLoginCodeChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setUserLoginCodeData(userLoginCode)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setUserLoginCodeData(userLoginCode);
                  _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }